import { rgba } from 'polished';
import React from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import useOperatorContext from '../../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import { Item } from '../../../../../contexts/site/site-context';
import { money } from '../../../../../services/format';
import {Preferences} from '../../MenuPage';
import mixpanel from "mixpanel-browser";
import {trackEvent} from "../../../../../services/api/operator";

type MenuItemProps = {
    item: Item;
    showItemModal: (item: Item, edit: boolean) => void;
    allowItemImages: boolean;
    preferences: Preferences;
    variant?: string;
};

const MenuItem = ({ item, showItemModal, allowItemImages, preferences, variant }: MenuItemProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();

    const basketItemCount = () => {
        let count = 0;
        basketContext.basketItems
            .filter((i) => item.uid === i.uid)
            .forEach((i) => count += (i.quantity ?? 0));
        return count;
    };

    const renderTitleBadges = () => {
        let badges = [];
        if (item.is_new) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-new`} className="badge--title">
                    <div className="badge--title__icon">
                        <i className="feather feather-star color-filter-badge-icon" />
                    </div>
                    <span className={'badge--title__text'}>
                         {t`New`}
                    </span>
                </Badge>,
            );
        }
        return badges.map((badge) => badge);
    };

    const renderDietBadges = () => {
        let badges = [];
        if (item.is_vegan) {
            badges.push(
                <OverlayTrigger
                    key={'veganPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-veganPlacement`}>
                            Suitable for Vegans
                        </Tooltip>
                    }
                >
                    <Badge className="badge-vg badge--menu-item" key={`tfbg-${item.uid}-vegan`} title="Vegan">
                        VG
                    </Badge>
                </OverlayTrigger>

            );
        }

        /*if (item.is_halal) {
            badges.push(
                <OverlayTrigger
                    key={'halalPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-halalPlacement`}>
                            Halal
                        </Tooltip>
                    }
                >
                    <Badge className="badge-h badge--menu-item" key={`tfbg-${item.uid}-halal`} title="Halal">
                        H
                    </Badge>
                </OverlayTrigger>
            );
        }*/

        if (item.is_vegetarian) {
            badges.push(
                <OverlayTrigger
                    key={'vegetarianPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-vegetarianPlacement2`}>
                            Suitable for Vegetarians
                        </Tooltip>
                    }
                >
                    <Badge className="badge-v badge--menu-item" key={`tfbg-${item.uid}-vegetarian`} title="Vegetarian">
                        V
                    </Badge>
                </OverlayTrigger>

            );
        }

        if (item.is_gluten_free) {
            badges.push(
                <OverlayTrigger
                    key={'gfPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-gfPlacement`}>
                            Gluten Free
                        </Tooltip>
                    }
                >
                    <Badge className="badge-gf badge--menu-item" key={`tfbg-${item.uid}-gf`} title="Gluten Free">
                        GF
                    </Badge>
                </OverlayTrigger>
            );
        }

        // if (item.allergens) {
        //     badges.push(<Badge key={`tfbg-${item.uid}-allergens`} variant="secondary">Allergens</Badge>)
        // }

        if (badges.length > 0) {
            return <>{badges.map((badge) => badge)}</>;
        }
        return null;
    };

    const renderDetails = () => {
        if ((item.description && styleVariant !== 'portrait') || item.calories !== '' || renderDietBadges() ) {
            return (
                <div className="menu-item__details">
                    {item.description ? (
                        <div className="menu-item__details__description">{item.description}</div>
                    ) : null}
                    { renderDietBadges() || item.calories !== '' ? (
                        <div className="menu-item__details__diet-info">
                            {(item.calories !== '' && item.calories && !preferences.calories) &&  <span className="menu-item__details__calories">{item.calories} kcal</span>}
                            { renderDietBadges() }
                        </div>
                    ) : null }
                </div>
            )
        } else {
            return null
        }
    }

    if ( item.is_vegan && !preferences.vegan ) { return null; }
    if ( item.is_vegetarian && !preferences.vegetarian ) { return null; }
    if ( item.is_gluten_free && !preferences.glutenFree ) { return null; }

    if ( item.allergens_list ) {
        let overlap = item.allergens_list.filter((allergen: string) => {
            return preferences[allergen as keyof Preferences]
        });
        if ( overlap.length > 0 ) { return null; }
    }

    const styleVariant = variant ?? operatorContext.operator?.theme?.v3.ux.menu_layout ?? 'classic';

    return styleVariant === 'portrait' ? (
        <StyledMenuItemPortraitWrapper>
            <StyledMenuItemPortrait
                className={`menu-item__wrapper ${item.image ? '' : 'no-image'} ${item.is_hidden ? 'unavailable-item' : ''}`}
                inBasket={basketItemCount() > 0}
                onClick={() => showItemModal(item, false)}
                variant={styleVariant}
                hasImage={item.image && allowItemImages}
            >
                <div className={'menu-item__absolute'}>
                    {item.is_hidden ? ( <div className="unavailable-item-overlay" /> ) : null}
                    {item.image && allowItemImages ? <CardImage image={item.image} className="menu-item__image" /> : null}

                    <div className="menu-item__content--portrait">
                        <div className="menu-item__top">
                            <div className="menu-item__header">
                                {basketItemCount() > 0 && basketItemCount() ? (
                                    <span className={'menu-item__basket-amount' + (item.image && allowItemImages ? ' menu-item__basket-amount--overlay' : '')}>
                                    {basketItemCount() > 0 && basketItemCount()}
                                </span>
                                ) : null }
                                <p className="menu-item__title">
                                    {/**
                                     * Change item count in basket
                                     */}

                                    <span className={'menu-item__title__text'}>{item.name}</span>
                                </p>
                            </div>
                            {renderDetails()}
                        </div>
                        <div className="menu-item__footer">
                            {item.is_hidden ? (
                                <div>
                                    <Badge key={`tfbg-${item.uid}-unavailable`} className="unavailable-item-text">
                                        <span className={'unavailable-item-text__md'}>{t`Currently unavailable`}</span>
                                        <span className={'unavailable-item-text__sm'}>{t`Unavailable`}</span>
                                    </Badge>
                                </div>
                            ) : <>
                                <div className="menu-item__footer__price">
                                    {money(item.price)}
                                </div>
                                {renderTitleBadges()}
                            </>}
                        </div>
                    </div>
                </div>
            </StyledMenuItemPortrait>
        </StyledMenuItemPortraitWrapper>
        ) : (

        /**
         * inBasket prop if item is in basket
         */

        <StyledMenuItem
            className={`menu-item__wrapper ${item.image ? '' : 'no-image'} ${item.is_hidden ? 'unavailable-item' : ''}`}
            inBasket={basketItemCount() > 0}
            onClick={() => {
                mixpanel.track('menu item modal' + ( variant === 'upsell' ? ' (upsell)' : '') );
                trackEvent('open-item', siteContext.site?.uid ?? '')
                showItemModal(item, false)
            }}
            variant={styleVariant}
        >
            {item.is_hidden ? ( <div className="unavailable-item-overlay" /> ) : null}
            <MenuItemContent className="menu-item" variant={styleVariant}>
                <div className="menu-item__header">
                    <p className="menu-item__title">
                        {/**
                         * Change item count in basket
                         */}

                        { basketItemCount() > 0 && <span className="menu-item__title__basket-amount">
                            {basketItemCount()}
                        </span> }

                        <span className={'menu-item__title__text'}>{item.name}</span>
                    </p>
                </div>
                {renderDetails()}
                <div className="menu-item__footer">
                    {item.is_hidden ? (
                            <div>
                                <Badge key={`tfbg-${item.uid}-unavailable`} className="unavailable-item-text">
                                    {t`Currently unavailable`}
                                </Badge>
                            </div>
                        ) : (
                        <>
                            <div className="menu-item__footer__price">
                                {money(item.price)}
                            </div>
                            {renderTitleBadges()}
                        </> )
                    }
                </div>
            </MenuItemContent>
            {item.image && allowItemImages ? <CardImage image={item.image} className="menu-item__image" /> : null}
        </StyledMenuItem>
    );
};

interface ImageProps {
    image: string | null;
}

const MenuItemContent = styled.div<{ variant?: string }>`
    
    ${(props) => props.variant === 'classic' && `
        @media screen and (max-width: 767px) {
            width: calc(100% - 136px - 15px);
        }
        @media screen and (min-width: 768px) {
            width: calc(100% - 170px - 14px);
        }
    `}
    
    @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 100%;
    }

    display: flex;
    flex-direction: column;
    
    .menu-item {
        &__header {
            display: flex;
            flex-direction: column;
            /* flex-grow: 1; */
        }

        &__title {
            margin-bottom: 4px;
            color: var(--text-black);
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            @supports (-webkit-line-clamp: 2) {
                white-space: initial;
                display: -webkit-inline-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            &__text {
                font-size: 16px;
                line-height: 22px;
                vertical-align: middle;
            }
        }
        
        @media screen and (max-width: 1140px) {

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }


        &__details {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            margin: 0 0 4px 0;
            flex-grow: 1;
            
            &__description {
                color: var(--text-grey);
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 4px;
                overflow: hidden;
                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            &__diet-info {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 4px;
            }
            &__calories {
                //font-size: 13px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--text-grey);
                display: inline-block;
            }
        }

        &__footer {
            display: flex;
            gap: 8px;
            align-items: center;
            &__price {
                font-weight: 400;

                font-size: 14px;
                line-height: 19px;
                
                color: var(--text-black);

                &--before {
                    text-decoration: line-through;
                    margin-left: 8px;
                    color: var(--text-grey);
                    font-weight: 400;
                }
                @media screen and (min-width: 576px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            
            .unavailable-item-text {
                color: var(--text-black-lite);
                padding: 0;
                margin-left: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                .unavailable-item-text__md {
                    display: none;
                }
                @media screen and (min-width: 400px) {
                    .unavailable-item-text__sm {
                        display: none;
                    }
                    .unavailable-item-text__md {
                        display: inline-block;
                    }
                }
                @media screen and (min-width: 576px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

        }
        

    }

`;


const StyledMenuItem = styled.div<{ inBasket?: boolean, variant?: string }>`
    position: relative;
    height: 176px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 16px;
    cursor: pointer;
    padding: 20px;
    transition: box-shadow 0.2s ease-in-out;

    @media screen and (min-width: 576px) {
        height: 164px;
    }

    ${(props) => props.inBasket && props.variant === 'classic' && `
        border-left: 4px solid var(--brand);
        padding-left: 16px !important;
    `};

    .menu-item__title__basket-amount {
        width: 18px;
        height: 18px;
        display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        background: var(--brand);
        font-size: 12px;
        padding: 2px 3px;
        border-radius: 4px;
        font-weight: 600;
        margin-right: 4px;
        vertical-align: middle;
        @media screen and (min-width: 576px) {
            font-size: 13px;
        }
    }

    @media screen and (min-width: 768px) {
        &:hover {           
            box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%);
        }
    }

    @media screen and (max-width: 767px) {
        border-left: ${(props) => props.inBasket && `4px solid var(--brand)`};
        padding-left: ${(props) => props.inBasket && `12px; `};
    }

    @media screen and (min-width: 768px) {
        height: 170px;
        border-radius: var(--ui_tile_radius);
        padding: 16px;
    }

    ${(props) => props.variant === 'classic' && `

        box-shadow: 0 -1px 0 var(--border-grey), 0 1px 0 var(--border-grey);
        @media screen and (min-width: 768px) {
            padding: 0 !important;
            .menu-item {
                padding: 20px;
                ${props.inBasket ? `padding-left: 16px;` : ''}
                &__image {
                    height: 168px;
                    width: 168px;
                    margin-right: 0px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    border-radius: 0;
                    border-top-right-radius: var(--ui_tile_radius);
                    border-bottom-right-radius: var(--ui_tile_radius);
                }
            }
        }
        &.no-image .menu-item {
            max-width: 100% !important;
            width: auto !important;
        }
    `}
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'dropshadow' && `
        @media screen and (min-width: 768px) {
            &:not(.unavailable-item) {
                box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 5%), 0 0px 2px rgba(0 0 0 / 5%);
            }
            &:hover { box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%); }
        }
    `}
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
        @media screen and (min-width: 768px) {
            border-radius: var(--ui_tile_radius);
            border: var(--card-border);
            ${props.inBasket ? `border-left: 4px solid var(--brand);` : ''}
            box-shadow: none;
            &:hover { box-shadow: 0 22px 24px 0 rgb(0 0 0 / 8%); }
        }
    `}
    

    ${(props) => (props.variant === 'upsell' && `
        gap: 0;
        width: 270px;
        height: 96px !important;
        border: 1px solid var(--border-grey);
        // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12) !important;
        border-radius: 8px;
        flex-shrink: 0;
        padding: 0 !important;
        overflow: visible;

        .menu-item {
            justify-content: space-between;
            padding: 12px 16px;
            flex-grow: 1;
            
            &__details__description { display: none; }
            
            &__footer__price {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
            }
            
            &__image {
                width: 96px;
                height: 96px;
                position: relative;
                top: -1px;
                right: -1px;
                border-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &__title__text {
                white-space: pre-wrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
            }
        }
        
        &.no-image {
            justify-content: flex-start;
        }
    `)}
    
    .unavailable-item-overlay {
        position: absolute;
        inset: 0 0 1px 0;
        background-color: rgba(255,255,255,0.5);
        z-index: 9;
        border-radius: var(--ui_tile_radius);
    }

    &.unavailable-item:hover {
        box-shadow: none;
        cursor: default;
    }
`;

const CardImage = styled.div<ImageProps>`
    background: center center no-repeat;
    background-size: cover;
    ${(props) =>
        props.image
            ? `background-image: url('${props.image}'); background-color: transparent;`
            : null};
    width: 136px;
    height: 136px;
    flex-shrink: 0;
    border-radius: 4px;
    
    @media screen and (min-width: 768px) {
        width: 136px;
        height: 136px;
    }

`;


const StyledMenuItemPortrait = styled.div<{ inBasket?: boolean, variant?: string, hasImage: boolean | '' }> `
    position: relative;
    gap: 0;
    height: 100%;
    cursor: pointer;
    border-radius: var(--ui_tile_radius);
    overflow: hidden;
    background-color: white;
    transition: box-shadow 0.2s ease-in-out;
    
    .menu-item__basket-amount {
        width: 20px;
        height: 20px;
        display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        background: var(--brand);
        font-size: 14px;
        padding: 2px 3px;
        border-radius: 4px;
        font-weight: 600;
        margin-right: 8px;
        margin-bottom: 8px;
        @media screen and (min-width: 576px) {
            font-size: 16px;
        }
        &--overlay {
            position: absolute;
            top: 12px;
            left: 12px;
            margin: 0;
        }
    }
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'dropshadow' && `
        &:not(.unavailable-item) {
            box-shadow: 0px 4px 10px -4px rgb(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 6%), 0 0px 2px rgba(0 0 0 / 6%);    
            &:hover {
                box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
            } 
        }
    `}
    
    
    ${(props) => props.theme.v3.ux.menu_tiles === 'flat' && `
        
        border-radius: var(--ui_tile_radius);
        border: 1px solid #EAEAEA;
        box-shadow: none;
        &:not(.unavailable-item) {
            &:hover {
                box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%) !important;
            }
        }
        
    `}
  
    
    .menu-item__details__description { display: none; }
    
    .menu-item__image {
        order: 1;
        width: calc(100% + 2px);
        object-fit: cover;
        position: relative;
        top: -1px;
        right: -1px;
        left: -1px;
        margin-bottom: -1px;
        border-radius: 8px 8px 0 0;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &.no-image {
        justify-content: flex-start;
        .menu-item__content--portrait {
            flex-grow: 1;
        }
        .menu-item__top {
            flex-grow: 0;
        }
    }
   
    padding-top: 140%;
    @media screen and (min-width: 576px) {
        padding-top: 105%;
    }
    
    .menu-item__absolute {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
    }

    .menu-item__content--portrait {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 10px 10px 6px 10px;
        border-bottom: solid 4px transparent;
        flex-basis: 101px;
        @media screen and (min-width: 576px) {
            flex-basis: 104px;
            gap: 4px;
            padding: 16px 16px 12px 16px;
            flex-grow: 0;
        }
        order: 2; 
        ${(props) => props.inBasket ? 'border-color: var(--brand);' : ''}
        .menu-item {
            &__header {
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 479px) {
                    margin-bottom: 0;
                }
                /* flex-grow: 1; */
            }
            &__top {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 4px;
                flex-basis: 67px;
                @media screen and (min-width: 576px) {
                    height: 64px;
                }
            }
            &__title {
                margin: 0;
                color: var(--text-black);
                overflow: hidden;
             
                &__text {
                    line-height: 22px;
                    vertical-align: middle;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-weight: 600;
                }
                &__basket-amount {
                    width: 20px;
                    height: 20px;
                    display: ${(props) => (!props.inBasket ? 'none' : 'inline-flex !important')};
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                    background: var(--brand);                  
                    font-size: 14px;
                    line-height: 22px;
                    padding: 2px 3px;
                    border-radius: 4px;
                    font-weight: 600;
                    margin-right: 8px;
                    @media screen and (min-width: 576px) {
                        font-size: 16px;
                    }
                }
            }
    
    
            &__details {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @media screen and (max-width: 479px) {
                    margin-bottom: 0;
                }
    
                &__description {
                    color: var(--text-grey);
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    overflow: hidden;
                }
                &__diet-info {
                    display: flex;
                    gap: 8px;
                }
                &__calories {
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 500;
                    color: var(--text-grey);
                    display: inline-block;
                }
            }
            &__footer {
                display: flex;
                gap: 5%;
                align-items: center;
                line-height: 0;
                @media screen and (min-width: 576px) {
                    gap: 8px;
                }

                &__price {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    @media screen and (min-width: 576px) {
                        font-size: 16px;
                        padding: 0;
                        line-height: 22px;
                    }
                    color: var(--text-black);
    
                    &--before {
                        text-decoration: line-through;
                        margin-left: 8px;
                        color: var(--text-grey);
                        font-weight: 400;
                    }
                }
                .unavailable-item-text {
                    color: var(--text-black-lite);
                    margin-left: 0;
                    padding: 0;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    .unavailable-item-text__md {
                        display: none;
                    }
                    @media screen and (min-width: 400px) {
                        .unavailable-item-text__sm {
                            display: none;
                        }
                        .unavailable-item-text__md {
                            display: inline-block;
                        }
                    }
                    @media screen and (min-width: 576px) {
                        font-size: 16px;
                        line-height: 22px;
                    }

                  font-size: 14px;
                  line-height: 19px;
                  
                    @media screen and (min-width: 576px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
    
            }
        }
    }
    .unavailable-item-overlay {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: rgba(255,255,255,0.5);
        z-index: 9;
        border-radius: var(--ui_tile_radius);
    }
`;

const StyledMenuItemPortraitWrapper = styled.div`
    margin: 0;
    @media screen and (min-width: 768px){
        margin: 0;
    }
`;

export default MenuItem;
